import { mapActions, mapGetters } from "vuex";
import { maxLength, required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/uk";

// Vue.use(Vuelidate)
import validation from "@/mixins/validation";

const typeOfActivityList = [
    { key: "private_practice_cosmetologist", title: "selectPrivatePracticeCosmetologist" },
    { key: "cosmetologist_injection_nurse", title: "selectCosmetologistInjectionNurse" },
    { key: "beauty_salon_studio", title: "selectBeautySalonStudio" },
    { key: "network_salon", title: "selectNetworkSalon" },
    { key: "beauty_clinic", title: "selectBeautyClinic" },
    { key: "network_clinic", title: "selectNetworkClinic" },
    { key: "online_store", title: "selectOnlineStore" },
    { key: "physical_store", title: "selectPhysicalStore" },
    { key: "dealer", title: "selectDealer" },
    { key: "masseur", title: "selectMasseur" },
    { key: "doctor", title: "selectDoctor" },
    { key: "dermatologist", title: "selectDermatologist" },
    { key: "aesthetic_medicine_doctor", title: "selectAestheticMedicineDoctor" },
];

const typeOfProceduresList = [
    { key: "all_care", title: "all_care" },
    { key: "cleaning", title: "cleaning" },
    { key: "problem_skin", title: "problem_skin" },
    { key: "massage", title: "massage" },
    { key: "injection_procedures", title: "injection_procedures" },
    { key: "dermatology", title: "dermatology" },
    { key: "anti_aging_therapy", title: "anti_aging_therapy" },
    { key: "peelings", title: "peelings" },
    { key: "laser_cosmetology_and_hair_removal", title: "laser_cosmetology_and_hair_removal" },
];


const sellingCareList = [
    { key: "redemption", title: "redemption" },
    { key: "showcase_realization", title: "showcase_realization" },
    { key: "spilling", title: "spilling" },
    { key: "online_store", title: "online_store" },
    { key: "community_group", title: "community_group" },
    { key: "official_skin_on_retailer", title: "official_skin_on_retailer" },
    { key: "no", title: "no" },
];

const workExperienceList = [
    { key: "to_1", title: "to_1" },
    { key: "from_1_to_5", title: "from_1_to_5" },
    { key: "from_5_to_10", title: "from_5_to_10" },
    { key: "from_10_to_20", title: "from_10_to_20" },
    { key: "from_20_to_30", title: "from_20_to_30" },
    { key: "more_than_30", title: "more_than_30" },
];

export default {
    name: "user-data",

    data() {
        return {
            typeOfActivityList: { ...typeOfActivityList },
            typeOfProceduresList: { ...typeOfProceduresList },
            sellingCareList: { ...sellingCareList },
            workExperienceList: { ...workExperienceList },
            payload: {
                name: "",
                lastName: "",
                secondName: "",
                country: "",
                city: "",
                workAddress: "",
                workName: "",
                typeOfActivity: null,
                typeOfActivityList: null,
                typeOfProcedures: null,
                typeOfProceduresList: null,
                sellingCare: null,
                sellingCareList: null,
                workExperience: null,
                workExperienceList: null,
                birth_date: null,
                social: null,
            },
            isEdit: false,
            validationErrors: {},
        };
    },
    mixins: [validation],
    validations() {
        const payload = {
            name: {
                required,
                maxLength: maxLength(191),
            },
            secondName: {
                required,
                maxLength: maxLength(191),
            },
            lastName: {
                required,
                maxLength: maxLength(191),
            },
            country: {
                required,
                maxLength: maxLength(191),
            },
            city: {
                required,
                maxLength: maxLength(191),
            },
            workAddress: {
                // required,
                maxLength: maxLength(191),
            },
            workName: {
                // required,
                maxLength: maxLength(191),
            },
        }

        if (this.isProfessional) {
            payload.typeOfActivity = {
                required,
            }
        }

        return {
            payload
        }
    },
    components: {
        DatePicker
    },
    computed: {
        ...mapGetters({
            user: "profile/user",
            isDistributor: "profile/isDistributor",
            isProfessional: "profile/isProfessional",
            updateUserLoading: "profile/updateUserLoading",
        }),
        secondNameErrors() {
            return this.computeErrors("payload", "secondName", {
                required: "validationRequired",
                maxLength: { message: "validationMax", count: 191 },
            });
        },
        typeOfActivityErrors() {
            return this.computeErrors("payload", "typeOfActivity", {
                required: "validationRequired",
            });
        },
        nameErrors() {
            return this.computeErrors("payload", "name", {
                required: "validationRequired",
                maxLength: { message: "validationMax", count: 191 },
            });
        },
        lastNameErrors() {
            return this.computeErrors("payload", "lastName", {
                required: "validationRequired",
                maxLength: { message: "validationMax", count: 191 },
            });
        },
        countryErrors() {
            return this.computeErrors("payload", "country", {
                required: "validationRequired",
                maxLength: { message: "validationMax", count: 191 },
            });
        },
        cityErrors() {
            return this.computeErrors("payload", "city", {
                required: "validationRequired",
                maxLength: { message: "validationMax", count: 191 },
            });
        },
        typeOfActivityText() {
            if (this.user.typeOfActivity) {
                const payloadTypeOfActivity = this.user.typeOfActivity.split(',');
                return payloadTypeOfActivity.map(item => this.$t(typeOfActivityList.find(e => e.title === item).title)).join(', ');
            } return ""
        },
        typeOfProceduresText() {
            if (this.user.typeOfProcedures) {
                const payloadTypeOfProcedures = this.user.typeOfProcedures.split(',');
                return payloadTypeOfProcedures.map(item => this.$t(typeOfProceduresList.find(e => e.title === item).title)).join(', ');
            }
            return ""
        },
        sellingCareText() {
            if ( this.user.sellingCare ) {
                const payloadSellingCare = this.user.sellingCare.split(',');
                return payloadSellingCare.map(item => this.$t(sellingCareList.find(e => e.title === item).title)).join(', ');
            } return ""
        },
        workAddressErrors() {
            return [];
        },
        workNameErrors() {
            return [];
        },
    },
    watch: {
        user(newVal) {
            if (newVal) {
                if (this.user) this.setUserInfo();
            }
        },
    },
    mounted() {
    },
    created() {
        if (this.user) this.setUserInfo();
        if (this.isProfessional || this.isDistributor) {
            this.$v.payload.work = {
                required,
                maxLength: maxLength(191),
            };
        }
    },
    methods: {
        ...mapActions({
            updateUserData: "profile/UPDATE_USER_DATA",
            login: "auth/GET_TOKEN",
        }),
        handleSelecttypeOfProcedure(value) {
            this.payload.typeOfProcedures = value.map(item => item.title);
            this.payload.typeOfProceduresList = value;
        },
        handleSelectSellingCare(value) {
            this.payload.sellingCare = value.map(item => item.title);
            this.payload.sellingCareList = value;
        },
        handleSelectWorkExperience(value) {
            this.payload.workExperience = value.map(item => item.title);
            this.payload.workExperienceList = value;
        },
        handleSelectTypeOfActivity(value) {
            this.payload.typeOfActivity = value.map(item => item.title);
            this.payload.typeOfActivityList = value;
        },
        resetForm() {
            this.isEdit = false;
            this.$v.$reset();
            this.setUserInfo();
        },
        setUserInfo() {
            this.payload.secondName = this.user.secondName;
            this.payload.name = this.user.name;
            this.payload.lastName = this.user.lastName;
            this.payload.country = this.user.country;
            this.payload.city = this.user.city;
            if (this.user.birthDate) {
                let birthDate = new Date(this.user.birthDate);
                birthDate.setHours(0, 0, 0, 0);
                console.log(birthDate)
                const year = birthDate.getFullYear();
                const month = birthDate.getMonth() + 1 < 10 ? `0${birthDate.getMonth() + 1}` : birthDate.getMonth() + 1;
                const day = birthDate.getDate() < 10 ? `0${birthDate.getDate()}` : birthDate.getDate();
                this.payload.birth_date = `${year}.${month}.${day}`;
            }
            this.payload.social = this.user.social;
            if (this.user.typeOfActivity) {
                this.payload.typeOfActivity = this.user.typeOfActivity.split(",");
            }
            if (this.user.typeOfProcedures) {
                this.payload.typeOfProcedures = this.user.typeOfProcedures.split(",");
            }
            if (this.user.sellingCare) {
                this.payload.sellingCare = this.user.sellingCare.split(",");
            }
            if (this.user.workExperience) {
                this.payload.workExperience = this.user.workExperience.split(",");
            }

            if (this.user.work) this.payload.work = this.user.work;
            if (this.user.workAddress) this.payload.workAddress = this.user.workAddress;
            if (this.user.workName) this.payload.workName = this.user.workName;
        },
        update() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let obj = {};
                obj.name = this.payload.name;
                obj.second_name = this.payload.secondName;
                obj.last_name = this.payload.lastName;
                obj.country = this.payload.country;
                obj.city = this.payload.city;
                obj.social = this.payload.social;
                obj.work_address = this.payload.workAddress;
                obj.work_name = this.payload.workName;
                if (this.payload.typeOfActivity) {
                    obj.type_of_activity = this.payload.typeOfActivity.join(",");
                }
                if (this.payload.typeOfProcedures) {
                    obj.type_of_procedures = this.payload.typeOfProcedures.join(",");
                }
                if (this.payload.sellingCare) {
                    obj.selling_care = this.payload.sellingCare.join(",");
                }
                if (this.payload.workExperience) {
                    obj.work_experience = this.payload.workExperience.join(",");
                }
                if (this.payload.birth_date) {
                    let birthDate = new Date(this.payload.birth_date);
                    birthDate.setHours(0, 0, 0, 0);
                    birthDate.setDate(birthDate.getDate() + 1);
                    obj.birth_date = birthDate;
                }

                if (this.isProfessional || this.isDistributor) obj.work = this.payload.work;
                this.updateUserData(obj).then(() => {
                    this.$toasted.success(this.$t("successResetData"));
                    this.isEdit = false;
                });
            }
        },
    },
};

